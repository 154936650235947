import {
	baseFormattedDate,
	Button,
	DatePicker,
	FilterExpanded,
	Form,
	formatAmount,
	notifService,
	Page,
	requestGQL,
	Table,
	useMyUrl,
	useSearchOf,
	useTranslation,
	Widget,
	Zone
} from '@gimlite/watermelon'
import { useEffect, useState } from 'react'
import { Collect } from '../../../types/collect'
import { collectsGql } from '../gql/collects.gql'
import { csvCollectsGql } from '../gql/csv-collects.gql'
import { CollectDrawer } from './collect-drawer.component'

type SearchParams = {
	startDate?: string
	stopDate?: string
	limit?: number
	page?: number
}

type RenderedCollect = {
	siteName?: string
	city?: string
	date?: string
	cardComment?: string
	chfAmount?: number
	eurAmount?: number
	defaultCurrency?: 'EUR' | 'CHF' | null
}


async function csvExport(searchParams: SearchParams) {
	const exportResp = await requestGQL({
		gql: csvCollectsGql,
		params: {
			...searchParams,
			targetTZ: Intl.DateTimeFormat().resolvedOptions().timeZone
		}
	})

	if (exportResp.downloadURL) {
		window.open(exportResp.downloadURL, '_blank')
		notifService.send('NOTIF', {
			content: 'The CSV file is being downloaded',
			mode: 'success'
		})
	} else {
		notifService.send('NOTIF', {
			content: 'Your CSV export failed',
			mode: 'error'
		})
	}
}


export const CollectsPage = () => {

	const { lang } = useTranslation()
	const { getParamsUrl, setParamsUrl } = useMyUrl()
	const [selectedCollectId, setSelectedCollectId] = useState<string | null>(
		null
	)

	const { list, paging, setSearch } = useSearchOf<Collect>({
		gql: collectsGql,
		searchParams: {},
		render: data => ({
			list: data.list.map((item: Collect) => ({
				...item,
				defaultCurrency: item.site?.defaultCurrency,
				date: item.logDate,
				siteName: item.site?.name || '',
				city: item.site?.city || '',
				cardComment: item.serviceCard?.comment || '',
				chfAmount:
					item?.currencyAmounts?.find(({ currency }) => currency === 'CHF')
						?.foreignAmount || 0,
				eurAmount:
					item?.currencyAmounts?.find(({ currency }) => currency === 'EUR')
						?.foreignAmount || 0
			})),
			paging: data.paging
		})
	})

	useEffect(() => {
		setSearch({
			paging: {
				page: getParamsUrl.page || 1,
				limit: getParamsUrl.limit || 15
			},
			params: getParamsUrl
		})
	}, [getParamsUrl])

	return (
		<Page>
			<Widget
				config={{ title: 'Rapports de collecte', subtitle: 'Module Finances' }}
			>
				<Zone
					config={{
						zones: [['filter'], ['table']],
						rows: ['min-content', '1fr'],
						columns: ['1fr']
					}}
				>
					<Zone.Area config={{ area: 'filter' }}>
						<FilterExpanded
							data={{
								value: {
									time:
										getParamsUrl?.startDate && getParamsUrl?.stopDate
											? [getParamsUrl.startDate, getParamsUrl.stopDate]
											: []
								}
							}}
							handleEvent={{
								submit: data => {
									let params: SearchParams = {}
									if (data.time) {
										params.startDate = data.time[0]
										params.stopDate = data.time[1]
									}

									setParamsUrl({
										...getParamsUrl,
										...params
									})
								}
							}}
						>
							<FilterExpanded.Fields>
								<Form.Item
									config={{
										name: 'time',
										label: 'Date'
									}}
								>
									<DatePicker
										config={{ mode: 'range', format: 'date', clear: true }}
									/>
								</Form.Item>
							</FilterExpanded.Fields>

							<FilterExpanded.Actions>
								<Button
									config={{
										text: 'Rechercher',
										type: { value: 'submit' }
									}}
								/>

								<Button
									handleEvent={{
										click: () => {
											setParamsUrl({})
										}
									}}
									config={{
										text: 'Nettoyer',
										disabled: !(
											getParamsUrl && Object.values(getParamsUrl).length > 0
										)
									}}
								/>

								<Button
									handleEvent={{
										click: () => csvExport(getParamsUrl)
									}}
									config={{
										text: 'CSV',
										icon: 'faFileDownloadSolid'
									}}
								/> 
							</FilterExpanded.Actions>
						</FilterExpanded>
					</Zone.Area>

					<Zone.Area config={{ area: 'table' }}>
						<>
							{list?.map((c: Collect) => (
								<CollectDrawer
									collect={c}
									isOpen={selectedCollectId === c._id}
									onClose={() => setSelectedCollectId(null)}
								/>
							))}

							<Table<Collect & RenderedCollect>
								handleEvent={{
									paging: (value: any) =>
										setParamsUrl({ ...getParamsUrl, ...value }),
									read: (value: any) => setSelectedCollectId(value._id)
								}}
								config={{
									pagination: 'click',
									columns: [
										{
											key: 'cid',
											title: 'No de collecte',
											sort: {
												sorter: (a, b) =>
													`${a.cid || ''}`.toLowerCase() >
													`${b.cid || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'cardComment',
											title: 'Carte de collecte',
											sort: {
												sorter: (a, b) =>
													`${a.cardComment || ''}`.toLowerCase() >
													`${b.cardComment || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'date',
											title: 'Date de collecte',
											sort: {
												sorter: (a, b) =>
													(a.date
														? new Date(b.date as unknown as any).getTime()
														: 0) -
													(b.date
														? new Date(a.date as unknown as any).getTime()
														: 0),
												defaultSortOrder: 'ascend'
											},
											render: value =>
												value.date
													? baseFormattedDate({ date: value.date, lang })
													: 'Non définie'
										},
										{
											key: 'siteName',
											title: 'Automate',
											sort: {
												sorter: (a, b) =>
													`${a.siteName || ''}`.toLowerCase() >
													`${b.siteName || ''}`.toLowerCase()
														? -1
														: 1
											}
										},
										{
											key: 'localAmount',
											title: 'Montant total',
											sort: {
												sorter: (a, b) =>
													(b.localAmount || 0) - (a.localAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.localAmount || 0,
													currency: value?.defaultCurrency || null,
													lang
												})
										},
										{
											key: 'chfAmount',
											title: 'Montant (CHF)',
											sort: {
												sorter: (a, b) =>
													(b.chfAmount || 0) - (a.chfAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.chfAmount || 0,
													currency: 'CHF',
													lang
												})
										},
										{
											key: 'eurAmount',
											title: 'Montant (EUR)',
											sort: {
												sorter: (a, b) =>
													(b.eurAmount || 0) - (a.eurAmount || 0)
											},
											render: value =>
												formatAmount({
													amount: value.eurAmount || 0,
													currency: 'EUR',
													lang
												})
										}
									],
									actions: { read: true }
								}}
								data={{
									list: list || [],
									paging: paging || {
										count: 0,
										current: 1,
										limit: 10
									}
								}}
							/>
						</>
					</Zone.Area>
				</Zone>
			</Widget>
		</Page>
	)
}
