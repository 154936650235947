import { Config, Keycloak } from '@gimlite/watermelon'
import { createRoot } from 'react-dom/client'
import { CustomRouter } from './CustomRouter'
import config from './config'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<Config
		debug={false}
		version={{
			env: config?.ENV,
			front: config?.VERSION_FRONT
		}}
		account={{
			updatePassword: true
		}}
		server={{
			bff: config.BFF_HOST,
			ws: config.BFF_WS_HOST
		}}
		isResponsive={false}
		authorization={config.BFF_TOKEN}
		theme={{
			color: {
				dark: '#5dbed2',
				light: '#0a2652'
			},
			defaultTheme: 'light'
		}}
		sso={{
			mode: { type: 'children', parentUrl: config.SSO_PARENT_URL },
			instance: new Keycloak({
				url: config.SSO_URL,
				realm: config.SSO_REALM,
				clientId: config.SSO_CLIENT_ID
			})
		}}
	>
		<CustomRouter />
	</Config>
)
