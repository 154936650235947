import { useRootStore } from '@gimlite/watermelon'
import { observer } from 'mobx-react-lite'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import MainUse from './main'
import { CollectsPage } from './pages/collects/view/collects.page'

export const CustomRouter = observer(() => {
	const { SSOStore } = useRootStore()

	const sitemap = [
		{
			path: '/',
			element: <MainUse />,
			children: [
				{
					index: true,
					element: <Navigate to={`/collects`} replace />
				},
				{
					path: '/collects',
					element: <CollectsPage />
				}
			]
		}
	]

	return SSOStore.authenticated && SSOStore.user ? (
		<RouterProvider router={createBrowserRouter(sitemap)}></RouterProvider>
	) : null
})
