import { gql } from '@apollo/client'

export const collectsGql = gql`
	query collects(
		$page: Int
		$limit: Int
		$order: String
		$startDate: String
		$stopDate: String
	) {
		collects(
			page: $page
			limit: $limit
			order: $order
			startDate: $startDate
			stopDate: $stopDate
		) {
			list {
				_id
				cid
				logDate
				localAmount
				trxCount

				coins
				currencyAmounts {
					currency
					foreignAmount
				}

				serviceCard {
					comment
					status
					type
				}

				site {
					name
					address1
					zipcode
					city
					defaultCurrency
				}
			}

			paging {
				count
				current
				limit
			}
		}
	}
`
