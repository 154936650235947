import { useNavigate } from '@gimlite/router'
import {
	translationService,
	useRootStore,
	useTranslation
} from '@gimlite/watermelon'
import IEM from '@gimlite/watermelon/assets/logo/IEM_small'
import { Main } from '@gimlite/watermelon/components/main/main.component'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

const MainUse = observer(() => {
	const { SSOStore } = useRootStore()
	const navigate = useNavigate()
	const { t, lang } = useTranslation()
	const me = SSOStore.user

	useEffect(() => {
		translationService.send('I18N', {
			operationName: 'i18n',
			operationType: 'QUERY'
		})
		translationService.send('SELECTED_LANG', { lang: 'fr_FR' })
	}, [])

	// useEffect(() => {
	// 	navigationService.send('REGISTER_MENU', {
	// 		menu: [
	// 			{
	// 				key: 'top',
	// 				position: 'top',
	// 				title: 'Gestion centralisee IEM Finance',
	// 				items: [
	// 					{
	// 						key: '/collects',
	// 						label: 'Rapport de collectes',
	// 						icon: 'faSackDollarSolid'
	// 					}
	// 					// {
	// 					// 	key: '/transactions',
	// 					// 	label: 'Rapport financier',
	// 					// 	icon: 'faBriefcaseSolid'
	// 					// }
	// 				]
	// 			}
	// 		]
	// 	})
	// }, [])

	return (
		<Main
			handleEvent={{
				selected: key => navigate(key)
			}}
			config={{
				navigation: {
					mode: 'drop'
				},
				//! Lors d'une refact du router, ce code apparaitra une fois en global (PROBLEM 1)
				//******* START ************/
				header: {
					logo: IEM()
				}
				//******* STOP ************/
			}}
		/>
	)
})

export default MainUse
